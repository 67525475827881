import React from "react";
import { Link } from "react-router-dom";
import vid from "../video/vid1.mp4";


const AgraTxt = () => {
  return (
    <div>
      <>
        {/* Page Header Start */}
        <div className="container-fluid px-0 mb-5 add1" style={{}}>
          <div
            id="header-carousel"
            className="carousel slide carousel-fade"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <video
                  src={vid}
                  autoPlay
                  loop
                  muted
                  style={{
                    width: "100%",
                    objectFit: "contain",
                    height: "600px",
                  }}
                />
                <div className="carousel-caption">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-lg-7 text-center"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Page Header End */}
        {/* About Start */}
        <div className="container-xxl add2">
          <div className="container">
            <div className="col-lg-12 wow fadeIn" data-wow-delay="0.5s">
              <div className="section-title" style={{}}>
                <h1>Why Visit Agra?</h1>
                <h2 className="py-3">
                  We Explain Why You Should Visit India’s Hub of Monuments
                </h2>
                <p
                  className=""
                  style={{
                    fontWeight: "400",
                    marginTop: "10px",
                  }}
                >
                  Agra is a city that is often visited for the legendary Taj
                  Mahal by tourists worldwide. However, this city which located
                  on the banks of River Yamuna has a lot more to offer! A hub of
                  historical monuments, Agra is often regarded as an unmissable
                  destination if you are touring India. We explain why!
                </p>
                <p>
                  <b>The Taj Mahal:</b> This architectural wonder needs no
                  introduction! It’s considered to be the most perfect symbol of
                  love worldwide as Emperor Shah Jehan got the legendary
                  monument constructed for his dearest wife Mumtaz Mahal.
                </p>

                <p>
                  <b>Fatehpur Sikri:</b> A miniature city is often listed as one
                  of the top destinations to visit in Agra. Fatehpur Sikri is
                  recognised as UNESCO World Heritage site and was the capital
                  city of the Mughal Empire back in the 16th Century!
                </p>
                <p>
                  <b>Bulund Darwaza:</b> The entrance of Fatehpur Sikri is the
                  Largest Entrance Gate in the World called the Bulund Darwaza.
                  Learn the grandeur and lifestyle of the kinds during the
                  Mughal rule by paying a visit to this structure.
                </p>
                <p>
                  <b>Agra Fort:</b> A UNESCO World Heritage site, the Agra fort
                  has seen many battles during its time. If you are visiting
                  Agra then visiting the Agra Fort cannot be missed.
                </p>
                <p>
                  <b>& Many More:</b> Agra also features many more structural
                  marvels such as Panch Mahal, Jama Masjid, Mehtab Bagh & More.
                  Plan a tour to Agra with Private Driver India and be assured
                  that you’d be visiting everything that Agra has to offer!
                </p>
              </div>
            </div>
            {/* <div className="row g-5">
              <div className="col-lg-6">
                <div className="row g-3">
                  <div className="col-6 text-end">
                    <img
                      className="img-fluid bg-white w-100 mb-3 wow fadeIn"
                      data-wow-delay="0.1s"
                      src="/static/img/image_1_1.png"
                      alt=""
                    />
                    <img
                      className="img-fluid bg-white w-50 wow fadeIn"
                      data-wow-delay="0.2s"
                      src="/static/img/image_1_1.png"
                      alt=""
                    />
                  </div>
                  <div className="col-6">
                    <img
                      className="img-fluid bg-white w-50 mb-3 wow fadeIn"
                      data-wow-delay="0.3s"
                      src="/static/img/image_1_1.png"
                      alt=""
                    />
                    <img
                      className="img-fluid bg-white w-100 wow fadeIn"
                      data-wow-delay="0.4s"
                      src="/static/img/image_1_1.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                <div className="section-title"></div>
                <div className="row g-3 mb-4">
                  <div className="col-sm-4">
                    <img
                      className="img-fluid bg-white w-100"
                      src="/static/img/image_1_1.png"
                      alt=""
                    />
                  </div>
                  <div className="col-sm-8">
                    <h5>
                      Our tea is one of the most popular drinks in the world
                    </h5>
                    <p className="mb-0">
                      Tempor erat elitr rebum at clita. Diam dolor diam ipsum
                      sit. Aliqu diam amet diam et eos. Clita erat ipsum et
                      lorem et sit
                    </p>
                  </div>
                </div>
                <div className="border-top mb-4" />
                <div className="row g-3">
                  <div className="col-sm-8">
                    <h5>
                      Daily use of Link cup of tea is good for your health
                    </h5>
                    <p className="mb-0">
                      Tempor erat elitr rebum at clita. Diam dolor diam ipsum
                      sit. Aliqu diam amet diam et eos. Clita erat ipsum et
                      lorem et sit
                    </p>
                  </div>
                  <div className="col-sm-4">
                    <img
                      className="img-fluid bg-white w-100"
                      src="/static/img/image_1_1.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {/* About End */}
      </>
    </div>
  );
};

export default AgraTxt;
