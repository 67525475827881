import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  return <div>
     {/* Navbar Start */}
  <div className="container-fluid bg-dark sticky-top">
    <div className="container">
      <nav className="navbar navbar-expand-lg bg-dark navbar-dark  py-lg-0" >
        <Link to="/" className="navbar-brand">
          <img className="img-fluid" src="/static/img/io.png" alt="Logo" />
        </Link>
        <button
          type="button"
          className="navbar-toggler ms-auto me-0"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto">        
            <Link to="/" className="nav-item nav-link active">
              Home
            </Link>
            <Link to="/whyus" className="nav-item nav-link">
              Why Us?
            </Link>
            <div className="nav-item dropdown">
              <Link
                to="#"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                Tour Packages
              </Link>
              <div className="dropdown-menu bg-light rounded-0 m-0">
                <Link to="/goldentriangle-india-tour" className="dropdown-item">
                Golden Triangle Tour
                </Link>
                <Link to="/rajasthan-tour" className="dropdown-item">
                Rajasthan Tour
                </Link>
                <Link to="/delhi-tour" className="dropdown-item">
                Delhi Tour
                </Link>
                <Link to="/agra-tour" className="dropdown-item">
                 Agra Tour
                </Link>
                <Link to="/south-india-tour" className="dropdown-item">
                 South India Tour
                </Link>
                <Link to="/nepal-tour" className="dropdown-item">
                 Nepal Tour
                </Link>
              </div>
            </div>
            <Link to="/photo-gallery" className="nav-item nav-link">
            Photo Gallery
            </Link>
            <Link to="/achievement" className="nav-item nav-link">
             Achievement
            </Link>
           
            <Link to="/reviews" className="nav-item nav-link">
              Reviews
            </Link>
            <Link to="/blogs" className="nav-item nav-link">
              Blogs
            </Link>
            <Link to="/contact" className="nav-item nav-link">
              Contact
            </Link>
          </div>
        </div>
      </nav>
    </div>
  </div>
  {/* Navbar End */}
  </div>;
};

export default Navbar;
