import React from "react";
import img6 from "../images/img5.jpg"

const Article = () => {
  return <div>
    {/* Article Start */}
  <div className="container-xxl py-5">
    <div className="container">
      <div className="row g-5">
        <div className="col-lg-5 wow fadeIn" data-wow-delay="0.1s">
          <img className="img-fluid" src={img6} alt="" />
        </div>
        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
          <div className="section-title">
            {/* <p className="fs-5 fw-medium fst-italic text-primary">
              Featured Acticle
            </p> */}
            {/* <h1 className="display-6">The history of tea leaf in the world</h1> */}
          </div>
          <p className="mb-4">
          The team at Privates Drivers India understands that organizing trips, especially to a culture rich country like India can be quite taxing! To address this, we have Helene who manages it all for our team at Privates Drivers India. With her guidance our team can craft the best packages for our customers to ensure that you do not miss anything in your journey to the wonderful land of India!
          </p>
          <p className="mb-4">
          Helene does this all by being in touch with our team of driver-guides who have years of experience and simply know the best and most wonderful sights to view in the magical land of India!
          </p>
          {/* <a href="" className="btn btn-primary rounded-pill py-3 px-5">
            Read More
          </a> */}
        </div>
      </div>
    </div>
  </div>
  {/* Article End */}
  </div>;
};

export default Article;
