import React from "react";
import { Link } from "react-router-dom";
import vid from "../video/vid1.mp4";


const GoldenTxt = () => {
  return (
    <div>
      <>
        {/* Page Header Start */}
        <div className="container-fluid px-0 mb-5 add1" style={{}}>
          <div
            id="header-carousel"
            className="carousel slide carousel-fade"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <video
                  src={vid}
                  autoPlay
                  loop
                  muted
                  style={{
                    width: "100%",
                    objectFit: "contain",
                    height: "600px",
                  }}
                />
                <div className="carousel-caption">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-lg-7 text-center"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Page Header End */}
        {/* About Start */}
        <div className="container-xxl add2">
          <div className="container">
            <div className="col-lg-12 wow fadeIn" data-wow-delay="0.5s">
              <div className="section-title" style={{}}>
                <p
                  className=""
                  style={{
                    fontWeight: "400",
                    marginTop: "40px",
                  }}
                >
                  The Golden Triangle tour is the most popular Private Driver
                  India travel package, the reason for this is simple. It’s
                  because within this tour package you get to discover the
                  Capital of India, New Delhi along with the Taj Mahal in Agra
                  and Jaipur which is situated in Rajasthan.
                </p>
                <p>
                  Across the variations of the Golden Triangle tour, the
                  highlights in Delhi will be Humayun’s Tomb, Lodhi Garden,
                  Qutub Minar, Akshardham Temple, Raj Ghat, India Gate and the
                  National Museum. Private Driver India and it’s drivers are
                  super flexible about any customizations as well, so in case
                  you need to add anything in specific to your tour you may do
                  so!
                </p>

                <p>
                  From Delhi, you will be transported to Agra and Rajasthan. In
                  Rajasthan you will tour the city of Jaipur. A chaotic and
                  colourful city where the historic Amber Fort stays on the
                  outskirts. While in Agra you will tour the Taj Mahal which is
                  hands down the biggest attraction in India. No India travel
                  journey is complete without some pictures with this legendary
                  monument!
                </p>
              </div>
            </div>
            <div className="row g-5">
              <h1 className="text-center">Golden Triangle Tour</h1>
              <h2 className="text-center">
                5 Nights & 6 Days: Delhi – Agra- Jaipur – Delhi
              </h2>
              <div className="col-lg-12">
                <div className="row g-4">
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.1s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/qutab1.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.3s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/red.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/taj.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h1 className="py-5">Itinerary:</h1>

            <div className="accordion py-5" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Day 1: Arrival
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    You will meet our driver in Delhi, he will sort you to the
                    pre-decided for your stay.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Day 2: Delhi
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On your second day you will get to see the Red Fort, Jama
                    Masjid, Humayun’s Tomb, Qutub Minar, the Parliament House
                    and any other custom destination that you might have added
                    to your package! After which you would stay overnight in the
                    Hotel.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Day 3: Delhi to Agra
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On the third day, we’d leave for Agra by road. Upon arrival
                    in Agra you will check into the pre-decided hotel and from
                    there we’d go to see Agra Fort, the Taj Mahal and any other
                    destination that you have selected. After this beautiful
                    sightseeing tour, you will rest overnight in the hotel.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Day 4: Agra to Jaipur
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On the fourth day’s morning we will leave for Jaipur by
                    road. On the way, we’d stop by at the Fatehpur Sikri and the
                    abandoned Mughal Town. Along the way and upon reaching you
                    will also see the Jodha Bai Palace, Panch Mahal, Buland
                    Darwaza and more spots! Once we reach Jaipur you’d check
                    into the hotel for night stay.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Day 5: Jaipur
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Jaipur is vast! So we will cover all the attractions in this
                    day. An elephant ride to Amber Fort will be on the cards and
                    road trip to the city will have you visiting more
                    attractions like Jantar Mantar, Nawab Sahab ki Haveli, the
                    local markets, etc. After this heavy day, you will return to
                    your hotel for the night stay.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Day 6: Jaipur to Delhi
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    After your morning breakfast we will leave for Delhi by
                    road. Upon arrival in Delhi you will be escorted to the
                    airport so you may return back to your country!
                  </div>
                </div>
              </div>
            </div>

            <div className="row g-5 py-5">
              <h1 className="text-center">Golden Triangle With Rishikesh</h1>
              <h2 className="text-center">
                6 Nights 7 Days: Delhi – Rishikesh – Agra – Jaipur – Delhi
              </h2>
              <div className="col-lg-12">
                <div className="row g-4">
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.1s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/qutab1.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.3s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/red.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/taj.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h1 className="py-5">Itinerary:</h1>

            <div className="accordion py-5" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven"
                    aria-expanded="true"
                    aria-controls="collapseSevrn"
                  >
                    Day 1: Arrival & Delhi
                  </button>
                </h2>
                <div
                  id="collapseSeven"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      In this Full Day Excursion we will explore the best of
                      Delhi with this private driver tour. Delhi is completely
                      full of some of the most amazing sites that India has to
                      offer, in this tour we will cover historical monuments,
                      forts, temples and shopping areas.
                    </p>

                    <p>
                      By embarking on this tour you’d also visit Old Delhi which
                      is a historic city and has a plethora of things for
                      tourists to do. Full Day sightseeing tour will cover
                      multiple historical monuments, Gardens and local markets
                      including:
                    </p>
                    <p>1.The Red Fort</p>
                    <p>2.Jama Masjid</p>
                    <p>3.Humayun’s Tomb</p>
                    <p>4.Rajpath</p>
                    <p>5.India Gate</p>
                    <p>6.Qutub Minar</p>
                    <p>7.Lotus Temple</p>
                    <p>8.Akshardham Temple</p>
                    <p>9.ISKON Temple</p>
                    <p>You will stay at the hotel overnight.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseEight"
                    aria-expanded="false"
                    aria-controls="collapseEight"
                  >
                    Day 2: Delhi – Rishikesh – Shivpuri
                  </button>
                </h2>
                <div
                  id="collapseEight"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    In the morning after the breakfast you will leave for
                    Shivpuri Nagar and also visit the Rishikesh Inrout.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseNine"
                    aria-expanded="false"
                    aria-controls="collapseNine"
                  >
                    Day 3: Shivpuri Camp
                  </button>
                </h2>
                <div
                  id="collapseNine"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    The third day will be filed with activities such as Rafting
                    and bonfire at night!
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTen"
                    aria-expanded="false"
                    aria-controls="collapseTen"
                  >
                    Day 4: Shivpuri Jungle Safari
                  </button>
                </h2>
                <div
                  id="collapseTen"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    After the breakfast you will visit the Rajaji National Park
                    Safari.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseEleven"
                    aria-expanded="false"
                    aria-controls="collapseEleven"
                  >
                    Day 5: Leaving for Agra
                  </button>
                </h2>
                <div
                  id="collapseEleven"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On Day 5 We’d leave for Agra and upon arrival in Agra you
                    will check into the pre-decided hotel and from there we’d go
                    to see Agra Fort, the Taj Mahal and any other destination
                    that you have selected. After this beautiful sightseeing
                    tour, you will rest overnight in the hotel.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwelve"
                    aria-expanded="false"
                    aria-controls="collapseTwelve"
                  >
                    Day 6: Agra – Jaipur
                  </button>
                </h2>
                <div
                  id="collapseTwelve"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On Day 6 we’ve leave for Jaipur. Jaipur is vast! So we will
                    cover all the attractions in this day. An elephant ride to
                    Amber Fort will be on the cards and road trip to the city
                    will have you visiting more attractions like Jantar Mantar,
                    Nawab Sahab ki Haveli, the local markets, etc. After this
                    heavy day, you will return to your hotel for the night stay.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThirteen"
                    aria-expanded="false"
                    aria-controls="collapseThirteen"
                  >
                    Day 7: Jaipur – Delhi
                  </button>
                </h2>
                <div
                  id="collapseThirteen"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On this day we’d head back to Delhi after which you can go
                    forward with your journey ahead!
                  </div>
                </div>
              </div>
            </div>

            <div className="row g-5">
              <h1 className="text-center">
                Golden Triangle With Ranthambore National Park
              </h1>
              <h2 className="text-center">
                7 Nights and 8 Days: Agra – Fatehpur Sikri – Ranthambore –
                Jaipur – Delhi
              </h2>
              <div className="col-lg-12">
                <div className="row g-4">
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.1s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/qutab1.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.3s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/red.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/taj.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h1 className="py-5">Itinerary:</h1>

            <div className="accordion py-5" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Day 1: Arrival
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    You will meet our driver in Delhi, he will sort you to the
                    pre-decided for your stay.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Day 2: Delhi
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On your second day you will get to see the Red Fort, Jama
                    Masjid, Humayun’s Tomb, Qutub Minar, the Parliament House
                    and any other custom destination that you might have added
                    to your package! After which you would stay overnight in the
                    Hotel.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Day 3: Delhi to Agra
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On the third day, we’d leave for Agra by road. Upon arrival
                    in Agra you will check into the pre-decided hotel and from
                    there we’d go to see Agra Fort, the Taj Mahal and any other
                    destination that you have selected. After this beautiful
                    sightseeing tour, you will rest overnight in the hotel.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Day 4: Agra – Fatehpur Sikari – Ranthambore
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On the fourth day of your journey, we will leave for
                    Ranthambore before stopping by at the Fatehpur Sikri. Once
                    you’ve arrived at Ranthambore you will check into the hotel
                    and plan the remainder of the day at your own leisure and
                    eventually stay overnight in the room.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Day 5: Exploring Ranthambore
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    In the morning of the fifth day, you’d leave for the morning
                    safari in the Ranthambore National Park and then return to
                    the hotel room for breakfast. Afternoon Safari is also
                    something you can opt for! Once the day ends, you can come
                    back to your room and spend the night at the hotel.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Day 6: Ranthambore – Jaipur
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    After breakfast, you’d leave for Jaipur and once you reach
                    Jaipur you will check in at the hotel and rest for the
                    remainder of the day. You will also spend the night at the
                    hotel.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Day 7: Jaipur
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Jaipur is vast! So we will cover all the attractions in this
                    day. An elephant ride to Amber Fort will be on the cards and
                    road trip to the city will have you visiting more
                    attractions like Jantar Mantar, Nawab Sahab ki Haveli, the
                    local markets, etc. After this heavy day, you will return to
                    your hotel for the night stay.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Day 8: Jaipur – Delhi
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    After your morning breakfast we will leave for Delhi by
                    road. Upon arrival in Delhi you will be escorted to the
                    airport so you may return back to your country!
                  </div>
                </div>
              </div>
            </div>

            <div className="row g-5">
              <h1 className="text-center">Golden Triangle With Varanasi</h1>
              <h2 className="text-center">
                8 Nights & 9 Days: Delhi – Jaipur – Agra – Varanasi – Delhi
              </h2>
              <div className="col-lg-12">
                <div className="row g-4">
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.1s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/qutab1.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.3s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/red.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/taj.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h1 className="py-5">Itinerary:</h1>

            <div className="accordion py-5" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Day 1: Arrival
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    You will meet our driver in Delhi, he will sort you to the
                    pre-decided for your stay.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Day 2: Delhi
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On your second day you will get to see the Red Fort, Jama
                    Masjid, Humayun’s Tomb, Qutub Minar, the Parliament House
                    and any other custom destination that you might have added
                    to your package! After which you would stay overnight in the
                    Hotel.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Day 3: Delhi – Jaipur
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On the third day’s morning we will drive you to the pink
                    city of Jaipur, upon your arrival you can check in to your
                    hotel and spend the rest of the day at your leisure.
                    Eventually, you’d be staying overnight at the hotel.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Day 4: Jaipur
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Jaipur is vast! So we will cover all the attractions in this
                    day. An elephant ride to Amber Fort will be on the cards and
                    road trip to the city will have you visiting more
                    attractions like Jantar Mantar, Nawab Sahab ki Haveli, the
                    local markets, etc. After this heavy day, you will return to
                    your hotel for the night stay.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Day 5: Jaipur – Agra
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    From Jaipur you’d leave for Agra after the breakfast on Day
                    5. En route you will see the Fatehpur Sikri and the
                    abandoned Mughal City. Rest of your day can be spent as per
                    your leisure. Eventually you’d stay overnight at the hotel.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Day 6: Agra & Overnight Train to Varanasi
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On the morning of the sixth day, you will visit the Agra
                    Fort and the legendary Taj Mahal. You will also visit Dayal
                    Bagh. In the evening you’d be transferred to Varanasi via an
                    overnight train.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Day 7: Varanasi
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Upon arrival in Varanasi you will check into the pre-decided
                    hotel and from there we’d go to see Durga Temple, Bharat
                    Mata Temple, Banras Hindu University’s art Gallery, Kashi
                    Vishwanath Temple and at the end of the day you’d go back to
                    your hotel for an overnight stay.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Day 8: Boat Ride & Sarnath
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    In the morning of the eighth day you’d enjoy a boat ride in
                    the Holi river Ganges and also see the bathing Ghats and the
                    cremation site. In the afternoon, we will visit Sarnath, the
                    Buddhist City where Lord Buddha gave his first sermon.
                    Eventually you’d return to the hotel for an overnight stay.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Day 9: Varanasi to Delhi and The Returning Home!
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On the last day of this journey you’d be transferred back to
                    Delhi from where you’ll board your onward flight to your
                    home city!
                  </div>
                </div>
              </div>
            </div>

            <div className="row g-5">
              <h1 className="text-center">Golden Triangle With Ladakh</h1>
              <h2 className="text-center">
                12 Nights & 13 Days: Delhi – Jaipur – Agra – Delhi – Ladakh
              </h2>
              <div className="col-lg-12">
                <div className="row g-4">
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.1s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/qutab1.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.3s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/red.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/taj.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h1 className="py-5">Itinerary:</h1>

            <div className="accordion py-5" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Day 1: Arrival in Delhi
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      You will meet our driver in Delhi, he will sort you to the
                      pre-decided for your stay.
                    </p>
                    <p>
                      You will get to see the Red Fort, Jama Masjid, Humayun’s
                      Tomb, Qutub Minar, the Parliament House and any other
                      custom destination that you might have added to your
                      package! After which you would stay overnight in the
                      Hotel.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Day 2: Delhi – Jaipur
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On the second day’s morning we will drive you to the pink
                    city of Jaipur, upon your arrival you can check in to your
                    hotel and spend the rest of the day at your leisure.
                    Eventually, you’d be staying overnight at the hotel.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Day 3: Jaipur
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Jaipur is vast! So we will cover all the attractions in this
                    day. An elephant ride to Amber Fort will be on the cards and
                    road trip to the city will have you visiting more
                    attractions like Jantar Mantar, Nawab Sahab ki Haveli, the
                    local markets, etc. After this heavy day, you will return to
                    your hotel for the night stay.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Day 4: Jaipur – Agra
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    From Jaipur you’d leave for Agra after the breakfast on Day
                    4. En route you will see the Fatehpur Sikri and the
                    abandoned Mughal City. Rest of your day can be spent as per
                    your leisure. Eventually you’d stay overnight at the hotel.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Day 5: Agra & Back to Delhi
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On the morning of the fifth day, you will visit the Agra
                    Fort and the legendary Taj Mahal. You will also visit Dayal
                    Bagh. In the noon you’d be transferred to Delhi.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Day 6: Delhi - Leh (Ladakh)
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On the sixth day after your breakfast we’d be transferring
                    you to Leh which is the capital of Ladakh province in the
                    state of Jammu & Kashmir. You will arrive at your hotel and
                    can spend the rest of the day at your leisure before your
                    overnight stay at the hotel.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Day 7: Exploring Leh
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On this day we will explore all that Leg has to offer! The
                    attractions will include the Sankar monastery, Thikse
                    Monastery, river rafting at the Indus River & more!
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Day 8 - Day 9: Leh - Nubra - Leh
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On Day 8 you will explore the Nubra Valley which is approx.
                    150km away from Leg. We will enjoy the gorgeous views that
                    this journey has to offer. Upon arrival you will explore at
                    your leisure before checking in for your overnight stay at
                    the hotel. On Day 9 after your breakfast you will enjoy all
                    the other attractions and views and then in the evening post
                    your lunch we will head back to Leh.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Day 10: Monastery Tour
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On Day 10 of this journey we will embark on the Monastery
                    tour which includes visitng the various monasteries in the
                    city!
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Day 11 - Day 12: Leh - Pangong Lake - Leh
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On these 2 days we will explore the Pangong Lake which is
                    just 4-5 hours away from Leh. We will enjoy all the
                    activities that it has to offer.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Day 13: Departure
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On the 13th day you will depart for your onward journey and
                    conclude this breathtaking trip!
                  </div>
                </div>
              </div>
            </div>

            <div className="container-xxl contact py-5">
              <div className="container">
                <div className="row g-5">
                  <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                    <h3 className="mb-4">Need a functional contact form?</h3>
                    <p className="mb-4">
                      The contact form is currently inactive. Get a functional
                      and working contact form with Ajax &amp; PHP in a few
                      minutes. Just copy and paste the files, add a little code
                      and you're done. <a href="">Download Now</a>.
                    </p>
                    <form>
                      <div className="row g-3">
                        <div className="col-md-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder="Your Name"
                            />
                            <label htmlFor="name">Your Name</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-floating">
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              placeholder="Your Email"
                            />
                            <label htmlFor="email">Your Email</label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              id="subject"
                              placeholder="Subject"
                            />
                            <label htmlFor="subject">Subject</label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-floating">
                            <textarea
                              className="form-control"
                              placeholder="Leave a message here"
                              id="message"
                              style={{ height: 120 }}
                              defaultValue={""}
                            />
                            <label htmlFor="message">Message</label>
                          </div>
                        </div>
                        <div className="col-12">
                          <button
                            className="btn btn-primary rounded-pill py-3 px-5"
                            type="submit"
                          >
                            Send Message
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* About End */}
      </>
    </div>
  );
};

export default GoldenTxt;
