import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import SouthTxt from "../components/SouthTxt";

const SouthIndiaTour = () => {
  return <div>
    <Navbar/>
    <SouthTxt/>
    <Footer/>
  </div>;
};

export default SouthIndiaTour;
