import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import DelhiTxt from "../components/DelhiTxt";

const DelhiTour = () => {
  return <div>
    <Navbar/>
    <DelhiTxt/>
    <Footer/>
  </div>;
};

export default DelhiTour;
