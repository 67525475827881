import React from "react";

const NewTxt = () => {
  return (
    <div>
      {/* Products Start */}
      <div className="container-fluid product py-5">
        <div className="container py-5">
          <div
            className="col-lg-12  wow fadeInUp"
            data-wow-delay="0.1s"
            // style={{ maxWidth: 500 }}
          >
            <h1 style={{ color: "#000", textAlign: "center" }}>
              North India Rajasthan
            </h1>
            <hr style={{ width: "100%", textAlign: "center", height: "2px" }} />
            <h3 style={{ color: "#000", textAlign: "center" }}>
              Golden Triangle Tour By Privates Drivers India
            </h3>
            <p className="py-2">
              We offer you a flexible and personal service so you can see India
              your own way. Below are a few of the private driver tours we offer
              – just for some inspiration!. All of our tours can be customised
              to suit your personal preferences, or request your own personal
              tour of India.
            </p>
            <div className="d-flex align-items-center justify-content-center">
              <p>
                To get you going, here is the list of our Top 3 Bestselling Tour
                Packages:
              </p>{" "}
              <br />
            </div>
            <div>
              <p className="text-center">1. Golden Triangle India Tour</p>
              <p className="text-center">2. North India Tour</p>
              <p className="text-center">3. Rajasthan Tour</p>
            </div>
          </div>
          <div className="col-lg-12 py-4 row g-4 d-flex align-items-center justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="store-item position-relative text-center">
                <img
                  className="img-fluid"
                  src="/static/img/7.png"
                  style={{
                    height: "400px",
                    width: "400px",
                    borderRadius: "50px",
                  }}
                  alt=""
                />
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="store-item position-relative text-center">
                <img
                  className="img-fluid"
                  src="/static/img/8.png"
                  alt=""
                  style={{
                    height: "400px",
                    width: "400px",
                    borderRadius: "50px",
                  }}
                />
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="store-item position-relative text-center">
                <img
                  className="img-fluid"
                  src="/static/img/9.png"
                  alt=""
                  style={{
                    height: "400px",
                    width: "400px",
                    borderRadius: "50px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Products End */}
    </div>
  );
};

export default NewTxt;
