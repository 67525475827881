import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import AgraTxt from "../components/AgraTxt";

const AgraTour = () => {
  return <div>
    <Navbar/>
    <AgraTxt/>
    <Footer/>
  </div>;
};

export default AgraTour;
