import React from "react";

const AboutTxt = () => {
  return <div>
      {/* About Start */}
  <div className="container-xxl py-5">
    <div className="container">
      <div className="row g-5">
        <div className="col-lg-6">
          <div className="row g-3">
            <h2>Why Privates Drivers India?</h2>
            <div className="col-6 text-end">
              <img
                className="img-fluid bg-white w-100 mb-3 wow fadeIn"
                data-wow-delay="0.1s"
                src="/static/img/img4.jpg"
                alt=""
              />
              <img
                className="img-fluid bg-white w-50 wow fadeIn"
                data-wow-delay="0.2s"
                src="/static/img/img7.jpg"
                alt=""
              />
            </div>
            <div className="col-6">
              <img
                className="img-fluid bg-white w-50 mb-3 wow fadeIn"
                data-wow-delay="0.3s"
                src="/static/img/img1.jpg"
                alt=""
              />
              <img
                className="img-fluid bg-white w-100 wow fadeIn"
                data-wow-delay="0.4s"
                src="/static/img/img5.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
          {/* <div className="section-title">
            <p className="fs-5 fw-medium fst-italic text-primary">About Us</p>
            <h1 className="display-6">
              The success history of TEA House in 25 years
            </h1>
          </div> */}
          <div className="row g-3 mb-4">
            <div className="col-sm-4">
              <img
                className="img-fluid bg-white w-100"
                src="/static/img/img8.jpg"
                alt=""
              />
            </div>
            <div className="col-sm-8">
              <h5>A Private Driver will be guiding you throughout the Journey!</h5>
              <p className="mb-0">
              Helene would be there for you in case you need anyone to advice you at any step of your journey with Privates Drivers India!
              </p>
            </div>
          </div>
          <div className="border-top mb-4" />
          <div className="row g-3">
            <div className="col-sm-8">
              <h5>Flexible Tour That is customized to your needs and interests</h5>
              <p className="mb-0">
              India's Most Fascinating Sites India offers some of the most breathtaking sights in the World. View all of that with us!
              </p>
            </div>
            <div className="col-sm-4">
              <img
                className="img-fluid bg-white w-100"
                src="/static/img/img10.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* About End */}
  </div>;
};

export default AboutTxt;
