import React from "react";

const Message = () => {
  return <div>
    {/* Contact Start */}
  <div className="container-xxl contact py-5">
    <div className="container">
      <div
        className="section-title text-center mx-auto wow fadeInUp"
        data-wow-delay="0.1s"
        style={{ maxWidth: 500 }}
      >
        <p className="fs-5 fw-medium fst-italic text-primary">Contact Us</p>
        <h1 className="display-6">Contact us right now</h1>
      </div>
      <div
        className="row justify-content-center wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <div className="col-lg-8">
          <div className="row g-5">
            <div
              className="col-md-4 text-center wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="btn-square mx-auto mb-3" style={{backgroundColor:"black"}}>
                <i className="fa fa-envelope fa-2x text-white" style={{backgroundColor:"black"}} />
              </div>
              <p className="mb-2">privatesdrivers12@gmail.com</p>
            </div>
            <div
              className="col-md-4 text-center wow fadeInUp"
              data-wow-delay="0.4s"
            >
              <div className="btn-square mx-auto mb-3" style={{backgroundColor:"black"}}>
                <i className="fa fa-phone fa-2x text-white" style={{backgroundColor:"black"}} />
              </div>
              <p className="mb-2">+91 9871713511</p>
            </div>
            <div
              className="col-md-4 text-center wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="btn-square mx-auto mb-3" style={{backgroundColor:"black"}}>
                <i className="fa fa-map-marker-alt fa-2x text-white" style={{backgroundColor:"black"}} />
              </div>
              <p className="mb-2">Rzf 406 Ambedkar Marg Rajnagar Part 2 Palam Colony, 110077</p>
              <p className="mb-0"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Contact Start */}
  </div>;
};

export default Message;
