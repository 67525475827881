import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ContactTxt from "../components/ContactTxt";

const Contact = () => {
  return <div>
    <Navbar/>
    <ContactTxt/>
    <Footer/>
  </div>;
};

export default Contact;
