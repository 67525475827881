import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import GalleryTxt from "../components/GalleryTxt";
import { Link } from "react-router-dom";

const PhotoGallery = () => {
  return <div>
    <Navbar/>
    {/* Page Header Start */}
  <div
    className="container-fluid page-header py-5 mb-5 wow fadeIn"
    data-wow-delay="0.1s"
  >
    <div className="container text-center py-5">
      <h1 className="display-2 text-white mb-4 animated slideInDown">
        Photo Gallery
      </h1>
      <nav aria-label="breadcrumb animated slideInDown">
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item text-dark" aria-current="page">
          Photo Gallery
          </li>
        </ol>
      </nav>
    </div>
  </div>
  {/* Page Header End */}
    <GalleryTxt/>
    <Footer/>
  </div>;
};

export default PhotoGallery;
