import React from "react";
import { Link } from "react-router-dom";
import vid from "../video/vid1.mp4";

const RajasthanTxt = () => {
  return (
    <div>
      <>
        {/* Page Header Start */}
        <div className="container-fluid px-0 mb-5 add1" style={{}}>
          <div
            id="header-carousel"
            className="carousel slide carousel-fade"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <video
                  src={vid}
                  autoPlay
                  loop
                  muted
                  style={{
                    width: "100%",
                    objectFit: "contain",
                    height: "600px",
                  }}
                />
                <div className="carousel-caption">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-lg-7 text-center"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Page Header End */}
        {/* About Start */}
        <div className="container-xxl py-5 add2">
          <div className="container">
            <div className="col-lg-12 wow fadeIn" data-wow-delay="0.5s">
              <div className="section-title" style={{}}>
                <h1>Why Visit Rajasthan?</h1>
                <h2 className="py-3">
                  We Explain Why You Should Visit India’s Wonderland!
                </h2>
                <p
                  className=""
                  style={{
                    fontWeight: "400",
                    marginTop: "10px",
                  }}
                >
                  Few reasons to Visit Rajasthan are:
                </p>
                <p>
                  1.Forts & Palaces: It is the home to maximum number of forts &
                  palaces in India! The architectural significance of these
                  forts and palaces are not just majestic, they are spell
                  bounding!
                </p>

                <p>
                  2.Wildlife Sanctuaries & National Parks: Rajasthan also has
                  some of the most famous wildlife sanctuaries in the world! and
                  when it comes to National Parks, the Ranthambore National Park
                  is a wonderful place to observe exotic animals.
                </p>
                <p>
                  3.Exotic Hotels: Several hotels across Rajasthan let you
                  experience the royal lifestyle first hand! The Rambagh Palace
                  is also known as the “Jewel of Jaipur” for the same reason!
                </p>
                <p>
                  4.Culture: Your trip to India isn’t complete till you have
                  absorbed all the culture that the state offers!
                </p>
                <p>
                  Private Driver India offers and endless amount of well-planned
                  Rajasthan Tours with Drivers who know India best! Contact us
                  and get lost in India’s Incredible Wonderland, Rajasthan!
                </p>
              </div>
            </div>

            <div className="row g-5">
              <h1 className="text-center">Majestic Rajasthan Tour</h1>
              <h2 className="text-center">
                9 Nights 10 Days: Delhi – Agra – Jaipur – Kishangarh – Pushkar –
                Udaipur – Ranakpur – Jodhpur – Delhi
              </h2>
              <div className="col-lg-12">
                <div className="row g-4">
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.1s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/raj7.jpg"
                        alt="img"
                        style={{
                          borderRadius: "7px 00px 7px 100px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.3s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/raj9.jpg"
                        alt="img"
                        style={{
                          borderRadius: "7px 100px 7px 100px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/raj10.jpg"
                        alt="img"
                        style={{
                          borderRadius: "7px 100px 7px 00px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h1 className="py-5">Itinerary:</h1>

            <div className="accordion py-5" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Day 1: Arrival
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    You will meet our driver in Delhi, he will sort you to the
                    pre-decided for your stay.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Day 2: Delhi
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <div className="accordion-body">
                      <p>
                        In this Full Day Excursion we will explore the best of
                        Delhi with this private driver tour. Delhi is completely
                        full of some of the most amazing sites that India has to
                        offer, in this tour we will cover historical monuments,
                        forts, temples and shopping areas.
                      </p>

                      <p>
                        By embarking on this tour you’d also visit Old Delhi
                        which is a historic city and has a plethora of things
                        for tourists to do. Full Day sightseeing tour will cover
                        multiple historical monuments, Gardens and local markets
                        including:
                      </p>
                      <p>1.The Red Fort</p>
                      <p>2.Jama Masjid</p>
                      <p>3.Humayun’s Tomb</p>
                      <p>4.Rajpath</p>
                      <p>5.India Gate</p>
                      <p>6.Qutub Minar</p>
                      <p>7.Lotus Temple</p>
                      <p>8.Akshardham Temple</p>
                      <p>9.ISKON Temple</p>
                      <p>
                        Experience the magnificence of Delhi and its history
                        with Private Driver India!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Day 3: Delhi to Agra
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    We will begin the day after your breakfast and then setting
                    our trip to Agra. Once in Agra, first we will visit the
                    legendary Taj Mahal and then visit the Tomb of Akhbar which
                    was a structure of unique combination that borrows its
                    styles from Hindu, Christian, Buddhist, Islamic and Jain
                    architectural conventions. We will also cover any specific
                    landmarks that you might have added at the time of booking
                    and in the evening you’d stay at the hotel overnight.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Day 4: Agra to Jaipur
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    After your breakfast we will leave for the city of Jaipur.
                    On the way to Jaipur you will see Fatehpur Sikri and visit
                    the Bulund Darwaza which is the tallets gateway in the
                    world! Once we reach Jaipur in the afternoon then you will
                    visit the traditional markets of Jaipur. After all this you
                    will stay overnight at Jaipur.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Day 5: Jaipur
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Jaipur is vast! So, we will cover all the attractions in
                    this day. An elephant ride to Amber Fort will be on the
                    cards and road trip to the city will have you visiting more
                    attractions like Jantar Mantar, Nawab Sahab ki Haveli, the
                    local markets, etc. After this heavy day, you will return to
                    your hotel for the night stay.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Day 6: Jaipur to Pushkar
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    After the morning breakfast we will head to our next
                    destination which is Pushkar! Considered to be one of the
                    holiest places for the Hindi community. In Pushkar we will
                    visit the famous Brahma Temple which is the only temple in
                    the world that is dedicated to Lord Brahma.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    7: Pushkar to Udaipur
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    After your breakfast on Day 7 we will drive towards Udaipur
                    which is also known as the Venice of the East! After all the
                    sightseeing in Udaipur you will stay overnight at the Hotel.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Day 8: Udaipur Sightseeing
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    There are a lot of attractions in Udaipur and a single day
                    just isn’t enough! We will visit the Garden of Maidens,
                    Jagdish Temple which was built 300 years ago and the Royal
                    City Palace which is situated at the banks of Lake Pichola.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Day 9: Udaipur – Jodhpur
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    In the morning we will leave for Jodhpur. Once we reach
                    Jodhpur you will be transferred to the Hotel and stay there
                    Overnight.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Day 10: Jodhpur Sightseeing – Delhi
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On the final day you will be shown all the amazing sights in
                    Jodhpur after which you will be transferred back to Delhi so
                    that you can head back home!
                  </div>
                </div>
              </div>
            </div>

            <div className="row g-5 py-5">
              <h1 className="text-center">Delhi - Agra - Jaipur Tour</h1>
              <h2 className="text-center">2 Nights and 3 Days</h2>
              <div className="col-lg-12">
                <div className="row g-4">
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.1s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/raj6.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.3s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/raj5.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/raj12.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h1 className="py-5">Itinerary:</h1>

            <div className="accordion py-5" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven"
                    aria-expanded="true"
                    aria-controls="collapseSevrn"
                  >
                    Day 1: Arrival at Delhi – Agra
                  </button>
                </h2>
                <div
                  id="collapseSeven"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      You will get to see the Red Fort, Jama Masjid, Humayun’s
                      Tomb, Qutub Minar, the Parliament House and any other
                      custom destination that you might have added to your
                      package! After which in the evening you will leave for
                      Agra and then stay overnight in Agra.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseEight"
                    aria-expanded="false"
                    aria-controls="collapseEight"
                  >
                    Day 2: Agra – Jaipur
                  </button>
                </h2>
                <div
                  id="collapseEight"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    We’d go to see Agra Fort, the Taj Mahal and any other
                    destination that you have selected. After this beautiful
                    sightseeing tour, you will leave for Jaipur and reach there
                    by night for an overnight stay.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseNine"
                    aria-expanded="false"
                    aria-controls="collapseNine"
                  >
                    Day 3: Jaipur – Delhi
                  </button>
                </h2>
                <div
                  id="collapseNine"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Jaipur is vast! So we will cover all the attractions in this
                    day. An elephant ride to Amber Fort will be on the cards and
                    road trip to the city will have you visiting more
                    attractions like Jantar Mantar, Nawab Sahab ki Haveli, the
                    local markets, etc. After this heavy day, you will start
                    your journey back to Delhi airport for your onward Journey!
                  </div>
                </div>
              </div>
            </div>

            <div className="row g-5">
              <h1 className="text-center">Rajasthan Tour With Thar Dessert</h1>
              <h2 className="text-center">
                13 Nights 14 Days: Delhi – Jodhpur – Rohet – Pokharan –
                Jaisalmer – Jodhpur – Delhi
              </h2>
              <div className="col-lg-12">
              <div className="row g-4">
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.1s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/raj7.jpg"
                        alt="img"
                        style={{
                          borderRadius: "7px 00px 7px 100px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.3s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/raj9.jpg"
                        alt="img"
                        style={{
                          borderRadius: "7px 100px 7px 100px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/raj10.jpg"
                        alt="img"
                        style={{
                          borderRadius: "7px 100px 7px 00px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h1 className="py-5">Itinerary:</h1>

            <div className="accordion py-5" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Day 1: Arrival
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    You will meet our driver in Delhi, he will sort you to the
                    pre-decided for your stay.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Day 2: Delhi
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <div className="accordion-body">
                      <p>
                        In this Full Day Excursion we will explore the best of
                        Delhi with this private driver tour. Delhi is completely
                        full of some of the most amazing sites that India has to
                        offer, in this tour we will cover historical monuments,
                        forts, temples and shopping areas.
                      </p>

                      <p>
                        By embarking on this tour you’d also visit Old Delhi
                        which is a historic city and has a plethora of things
                        for tourists to do. Full Day sightseeing tour will cover
                        multiple historical monuments, Gardens and local markets
                        including:
                      </p>
                      <p>1.The Red Fort</p>
                      <p>2.Jama Masjid</p>
                      <p>3.Humayun’s Tomb</p>
                      <p>4.Rajpath</p>
                      <p>5.India Gate</p>
                      <p>6.Qutub Minar</p>
                      <p>7.Lotus Temple</p>
                      <p>8.Akshardham Temple</p>
                      <p>9.ISKON Temple</p>
                      <p>
                        Experience the magnificence of Delhi and its history
                        with Private Driver India!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Day 3: Delhi – Jodhpur By Flight and Jodhpur to Rohet By
                    Road
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On day 3 of this trip, after breakfast you will be
                    transferred to Jodhpur which is also known as the “Sun City”
                    by flight. This city also lies on the edge of the world
                    renowned Thar Dessert. After this sightseeing you will be
                    driven to Rohet Garh which offers a variety of safaris such
                    as Jeep Safari, Horse Safari and even bird watching tours!
                    In the evening you would be driven to your hotel for an
                    overnight stay at Rohet.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Day 4: Rohet – Pokhran
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    After your breakfast we will head to our next destination
                    which is Pokhran. On our way we will witness the Mahrangarh
                    fort which is in the heart of the city and is also easily
                    approachable. After reaching Pokhran we will head for the
                    hotel for an overnight stay.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Day 5 – Day 11: Safari through the Sand Dunes
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    From the 5th Day till the 11th Day you will be on a great
                    Safari tour through the villages of the Thar Dessert! During
                    this wonderful journey we will cover many known villages
                    such as Chaba Village, Baniyana Village, Ujala Vilage,
                    Falsund Village, Dangri Village, Rasla, Nehadie Dwada, Hapa,
                    Janra, Khuri Village, and many more!
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Day 12: Drive to Jaisalmer
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On the 12th Day of this trip we will drive back to Jaisalmer
                    which is known as the “Golden Town: we will visit the
                    Jaisalmer Fort and also Salim Singh Haveli. We will stay in
                    Jaisalmer overnight.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Day 13: Jaisalmer – Jodhpur – Delhi By Flight
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    After your breakfast on the 13th day you will be taken back
                    to Jodhpur airport for your flight back to Delhi. You will
                    stay in Delhi overnight
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Day 14: Heading Back Home
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    After the breakfast on this day you will be escorted to the
                    Delhi International Airport so you can head back home!
                  </div>
                </div>
              </div>
            </div>

            <div className="row g-5">
              <h1 className="text-center">Rajasthan Wildlife Tour</h1>
              <h2 className="text-center">
                10 Days 11 Nights: Delhi – Agra – Ranthambhore – Jaipur –
                Pushkar – Udaipur – Delhi
              </h2>
              <div className="col-lg-12">
              <div className="row g-4">
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.1s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/raj7.jpg"
                        alt="img"
                        style={{
                          borderRadius: "7px 00px 7px 100px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.3s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/raj9.jpg"
                        alt="img"
                        style={{
                          borderRadius: "7px 100px 7px 100px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/raj10.jpg"
                        alt="img"
                        style={{
                          borderRadius: "7px 100px 7px 00px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h1 className="py-5">Itinerary:</h1>

            <div className="accordion py-5" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Day 1: Arrival
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    You will meet our driver in Delhi, he will sort you to the
                    pre-decided for your stay.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Day 2: Delhi
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <div className="accordion-body">
                      <p>
                        In this Full Day Excursion we will explore the best of
                        Delhi with this private driver tour. Delhi is completely
                        full of some of the most amazing sites that India has to
                        offer, in this tour we will cover historical monuments,
                        forts, temples and shopping areas.
                      </p>

                      <p>
                        By embarking on this tour you’d also visit Old Delhi
                        which is a historic city and has a plethora of things
                        for tourists to do. Full Day sightseeing tour will cover
                        multiple historical monuments, Gardens and local markets
                        including:
                      </p>
                      <p>1.The Red Fort</p>
                      <p>2.Jama Masjid</p>
                      <p>3.Humayun’s Tomb</p>
                      <p>4.Rajpath</p>
                      <p>5.India Gate</p>
                      <p>6.Qutub Minar</p>
                      <p>7.Lotus Temple</p>
                      <p>8.Akshardham Temple</p>
                      <p>9.ISKON Temple</p>
                      <p>
                        Experience the magnificence of Delhi and its history
                        with Private Driver India!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Day 3: Delhi - Agra
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    We will begin the day after your breakfast and then setting
                    our trip to Agra. Once in Agra, first we will visit the
                    legendary Taj Mahal and then visit the Tomb of Akhbar which
                    was a structure of unique combination that borrows its
                    styles from Hindu, Christian, Buddhist, Islamic and Jain
                    architectural conventions. We will also cover any specific
                    landmarks that you might have added at the time of booking
                    and in the evening you’d stay at the hotel overnight.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Day 4: Agra – Ranthambore National Park
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On this day we will drive towards Ranthambhore which is one
                    of the most renowned tiger reserves in India. You will stay
                    overnight in Ranthambhore.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Day 5 & Day 6: Exploring Ranthambhore National Park
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On the 5th and 6th day of this journey you will explore all
                    aspects of the Park and spot many wild animals such as
                    jackals, jungle cats, wild boars, Sambhar, Tigers, Leopars
                    and many more! on Day 6 we’d head back to Jaipur and stay
                    there overnight.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Day 7: Exploring Jaipur
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Jaipur is vast! So we will cover all the attractions in this
                    day. An elephant ride to Amber Fort will be on the cards and
                    road trip to the city will have you visiting more
                    attractions like Jantar Mantar, Nawab Sahab ki Haveli, the
                    local markets, etc. Your overnight stay will be in Jaipur.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Day 8: Jaipur – Pushkar
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    After the breakfast we’d head for Pushkar where we will
                    visit the temples including the Brahma Temple which is the
                    only place of worship that has been devoted to Deity Brahma.
                    Before the overnight stay we will see some sights like
                    Pushkar Lake.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Day 9: Pushkar – Udaipur
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    In the morning of the eighth day you’d enjoy a boat ride in
                    the Holi river Ganges and also see the bathing Ghats and the
                    cremation site. In the afternoon, we will visit Sarnath, the
                    Buddhist City where Lord Buddha gave his first sermon.
                    Eventually you’d return to the hotel for an overnight stay.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Day 10: Udaipur
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On the last day of this journey you’d be transferred back to
                    Delhi from where you’ll board your onward flight to your
                    home city!
                  </div>
                </div>
              </div>
            </div>

            <div className="row g-5">
              <h1 className="text-center">
                Rajasthan Tour With Khajuraho & Varanasi
              </h1>
              <h2 className="text-center">
                22 Nights 23 Days: Delhi – Jaipur – Mandawa – Bikaner –
                Jaisalmer – Jodhpur – Udaipur – Bundi – Ranthambhore – Agra –
                Orchha – Khajuraho – Varanasi
              </h2>
              <div className="col-lg-12">
              <div className="row g-4">
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.1s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/raj7.jpg"
                        alt="img"
                        style={{
                          borderRadius: "7px 00px 7px 100px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.3s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/raj9.jpg"
                        alt="img"
                        style={{
                          borderRadius: "7px 100px 7px 100px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/raj10.jpg"
                        alt="img"
                        style={{
                          borderRadius: "7px 100px 7px 00px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h1 className="py-5">Itinerary:</h1>

            <div className="accordion py-5" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Day 1: Arrival in Delhi
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <div className="accordion-body">
                      <p>
                        In this Full Day Excursion we will explore the best of
                        Delhi with this private driver tour. Delhi is completely
                        full of some of the most amazing sites that India has to
                        offer, in this tour we will cover historical monuments,
                        forts, temples and shopping areas.
                      </p>

                      <p>
                        By embarking on this tour you’d also visit Old Delhi
                        which is a historic city and has a plethora of things
                        for tourists to do. Full Day sightseeing tour will cover
                        multiple historical monuments, Gardens and local markets
                        including:
                      </p>
                      <p>1.The Red Fort</p>
                      <p>2.Jama Masjid</p>
                      <p>3.Humayun’s Tomb</p>
                      <p>4.Rajpath</p>
                      <p>5.India Gate</p>
                      <p>6.Qutub Minar</p>
                      <p>7.Lotus Temple</p>
                      <p>8.Akshardham Temple</p>
                      <p>9.ISKON Temple</p>
                      <p>
                        Experience the magnificence of Delhi and its history
                        with Private Driver India!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Day 2: Exploring Delhi
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On the second day’s morning we will drive you to the pink
                    city of Jaipur, upon your arrival you can check in to your
                    hotel and spend the rest of the day at your leisure.
                    Eventually, you’d be staying overnight at the hotel.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Day 3: Delhi – Jaipur
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Jaipur is vast! So we will cover all the attractions in this
                    day. An elephant ride to Amber Fort will be on the cards and
                    road trip to the city will have you visiting more
                    attractions like Jantar Mantar, Nawab Sahab ki Haveli, the
                    local markets, etc. After this heavy day, you will return to
                    your hotel for the night stay.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Day 4: Exploring Jaipur
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    From Jaipur you’d leave for Agra after the breakfast on Day
                    4. En route you will see the Fatehpur Sikri and the
                    abandoned Mughal City. Rest of your day can be spent as per
                    your leisure. Eventually you’d stay overnight at the hotel.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Day 5: Jaipur – Mandawa
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On the morning of the fifth day, you will visit the Agra
                    Fort and the legendary Taj Mahal. You will also visit Dayal
                    Bagh. In the noon you’d be transferred to Delhi.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Day 6: Mandawa – Bikaner
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On the sixth day after your breakfast we’d be transferring
                    you to Leh which is the capital of Ladakh province in the
                    state of Jammu & Kashmir. You will arrive at your hotel and
                    can spend the rest of the day at your leisure before your
                    overnight stay at the hotel.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Day 7: Bikaner – Jaisalmer
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On this day we will explore all that Leg has to offer! The
                    attractions will include the Sankar monastery, Thikse
                    Monastery, river rafting at the Indus River & more!
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Day 8: Exploring Jaisalmer
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On Day 8 you will explore the Nubra Valley which is approx.
                    150km away from Leg. We will enjoy the gorgeous views that
                    this journey has to offer. Upon arrival you will explore at
                    your leisure before checking in for your overnight stay at
                    the hotel. On Day 9 after your breakfast you will enjoy all
                    the other attractions and views and then in the evening post
                    your lunch we will head back to Leh.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Day 8: Exploring Jaisalmer
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On Day 10 of this journey we will embark on the Monastery
                    tour which includes visitng the various monasteries in the
                    city!
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Day 9: Jaisalmer – Jodhpur
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On these 2 days we will explore the Pangong Lake which is
                    just 4-5 hours away from Leh. We will enjoy all the
                    activities that it has to offer.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Day 10: Exploring Jodhpur
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On the 13th day you will depart for your onward journey and
                    conclude this breathtaking trip!
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Day 10: Exploring Jodhpur
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On the 13th day you will depart for your onward journey and
                    conclude this breathtaking trip!
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Day 10: Exploring Jodhpur
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On the 13th day you will depart for your onward journey and
                    conclude this breathtaking trip!
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Day 10: Exploring Jodhpur
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    On the 13th day you will depart for your onward journey and
                    conclude this breathtaking trip!
                  </div>
                </div>
              </div>
            </div>

            <div className="container-xxl contact py-5">
              <div className="container">
                <div className="row g-5">
                  <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                    <h3 className="mb-4">Need a functional contact form?</h3>
                    <p className="mb-4">
                      The contact form is currently inactive. Get a functional
                      and working contact form with Ajax &amp; PHP in a few
                      minutes. Just copy and paste the files, add a little code
                      and you're done. <a href="">Download Now</a>.
                    </p>
                    <form>
                      <div className="row g-3">
                        <div className="col-md-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder="Your Name"
                            />
                            <label htmlFor="name">Your Name</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-floating">
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              placeholder="Your Email"
                            />
                            <label htmlFor="email">Your Email</label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              id="subject"
                              placeholder="Subject"
                            />
                            <label htmlFor="subject">Subject</label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-floating">
                            <textarea
                              className="form-control"
                              placeholder="Leave a message here"
                              id="message"
                              style={{ height: 120 }}
                              defaultValue={""}
                            />
                            <label htmlFor="message">Message</label>
                          </div>
                        </div>
                        <div className="col-12">
                          <button
                            className="btn btn-primary rounded-pill py-3 px-5"
                            type="submit"
                          >
                            Send Message
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
{/* 
            <div className="row g-5">
              <div className="col-lg-6">
                <div className="row g-3">
                  <div className="col-6 text-end">
                    <img
                      className="img-fluid bg-white w-100 mb-3 wow fadeIn"
                      data-wow-delay="0.1s"
                      src="/static/img/image_1_1.png"
                      alt=""
                    />
                    <img
                      className="img-fluid bg-white w-50 wow fadeIn"
                      data-wow-delay="0.2s"
                      src="/static/img/image_1_1.png"
                      alt=""
                    />
                  </div>
                  <div className="col-6">
                    <img
                      className="img-fluid bg-white w-50 mb-3 wow fadeIn"
                      data-wow-delay="0.3s"
                      src="/static/img/image_1_1.png"
                      alt=""
                    />
                    <img
                      className="img-fluid bg-white w-100 wow fadeIn"
                      data-wow-delay="0.4s"
                      src="/static/img/image_1_1.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                <div className="section-title"></div>
                <div className="row g-3 mb-4">
                  <div className="col-sm-4">
                    <img
                      className="img-fluid bg-white w-100"
                      src="/static/img/image_1_1.png"
                      alt=""
                    />
                  </div>
                  <div className="col-sm-8">
                    <h5>
                      Our tea is one of the most popular drinks in the world
                    </h5>
                    <p className="mb-0">
                      Tempor erat elitr rebum at clita. Diam dolor diam ipsum
                      sit. Aliqu diam amet diam et eos. Clita erat ipsum et
                      lorem et sit
                    </p>
                  </div>
                </div>
                <div className="border-top mb-4" />
                <div className="row g-3">
                  <div className="col-sm-8">
                    <h5>
                      Daily use of Link cup of tea is good for your health
                    </h5>
                    <p className="mb-0">
                      Tempor erat elitr rebum at clita. Diam dolor diam ipsum
                      sit. Aliqu diam amet diam et eos. Clita erat ipsum et
                      lorem et sit
                    </p>
                  </div>
                  <div className="col-sm-4">
                    <img
                      className="img-fluid bg-white w-100"
                      src="/static/img/image_1_1.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {/* About End */}
      </>
    </div>
  );
};

export default RajasthanTxt;
