import React from "react";

const Store = () => {
  return (
    <div>
      {/* Store Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="section-title text-center mx-auto wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: 500 }}
          >
          </div>
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="store-item position-relative text-center">
                <img className="img-fluid" src="/static/img/img5.jpg" alt="" />
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="store-item position-relative text-center">
                <img className="img-fluid" src="/static/img/img7.jpg" alt="" />
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="store-item position-relative text-center">
                <img className="img-fluid" src="/static/img/img8.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Store End */}
    </div>
  );
};

export default Store;
