import './App.css';
import Test from './components/Test';
import Home from './pages/Home';
import Aboutus from "./pages/Aboutus"
import Whyus from "./pages/Whyus"
import DelhiTour from './pages/DelhiTour';
import GoldenTriangleIndiaTour from "./pages/GoldenTriangleIndiaTour";
import NepalTour from './pages/NepalTour';
import RajasthanTour from './pages/RajasthanTour';
import SouthIndiaTour from './pages/SouthIndiaTour';
import AgraTour from './pages/AgraTour';
import PhotoGallery from './pages/PhotoGallery';
import Achievement from './pages/Achievement';
import Reviews from './pages/Reviews';
import Blogs from './pages/Blogs';
import Contact from './pages/Contact';
import { Routes, Route } from 'react-router-dom';

function App() {
  return (
    <>
    <Routes>
      <Route path='/' element={<> <Home/> </>} />
      <Route path='/about' element={<> <Aboutus/> </>} />
      <Route path='/whyus' element={<> <Whyus/> </>} />
      <Route path='/delhi-tour' element={<> <DelhiTour/> </>} />
      <Route path='/nepal-tour' element={<> <NepalTour/> </>} />
      <Route path='/rajasthan-tour' element={<> <RajasthanTour/> </>} />
      <Route path='/goldentriangle-india-tour' element={<> <GoldenTriangleIndiaTour/> </>} />
      <Route path='/south-india-tour' element={<> <SouthIndiaTour/> </>} />
      <Route path='/agra-tour' element={<> <AgraTour/> </>} />
      <Route path='/photo-gallery' element={<> <PhotoGallery/> </>} />
      <Route path='/achievement' element={<> <Achievement/> </>} />
      <Route path='/reviews' element={<> <Reviews/> </>} />
      <Route path='/blogs' element={<> <Blogs/> </>} />
      <Route path='/contact' element={<> <Contact/> </>} />
    </Routes>
    </>
  );
}

export default App;
