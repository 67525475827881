import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return <div>
      {/* Footer Start */}
  <div
    className="container-fluid bg-dark footer mt-5 py-5 wow fadeIn"
    data-wow-delay="0.1s" 
    style={{
      backgroundColor:"#fff"
    }}
  >
    <div className="container py-5">
      <div className="row g-5">
        <div className="col-lg-3 col-md-6">
          <h4 className="text mb-4" style={{color:"#fff"}}>Our Office</h4>
          <p className="mb-2">
            <i className="fa fa-map-marker-alt text me-3" />
            Rzf 406 Ambedkar Marg Rajnagar Part 2 Palam Colony, 110077
          </p>
          <p className="mb-2">
            <i className="fa fa-phone-alt text me-3" />
            +91 9871713511
          </p>
          <p className="mb-2">
            <i className="fa fa-envelope text me-3" />
            privatesdrivers12@gmail.com
          </p>
          <div className="d-flex pt-3">
            <Link
              className="btn btn-square btn-primary rounded-circle me-2"
              to=""
            >
              <i className="fab fa-twitter" />
            </Link>
            <Link
              className="btn btn-square btn-primary rounded-circle me-2"
              to=""
            >
              <i className="fab fa-facebook-f" />
            </Link>
            <Link
              className="btn btn-square btn-primary rounded-circle me-2"
              to=""
            >
              <i className="fab fa-youtube" />
            </Link>
            <Link
              className="btn btn-square btn-primary rounded-circle me-2"
              to=""
            >
              <i className="fab fa-linkedin-in" />
            </Link>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <h4 className="text-white mb-4">Quick Links</h4>
          <Link className="btn btn-link" to="/">
            Home
          </Link>
          <Link className="btn btn-link" to="/whyus">
            Why Us
          </Link>
          <Link className="btn btn-link" to="/achievement">
            Achievement
          </Link>
          <Link className="btn btn-link" to="/reviews">
            Reviews
          </Link>
          <Link className="btn btn-link" to="/blogs">
            Blogs
          </Link>
          <Link className="btn btn-link" to="/contact">
            Contact
          </Link>
        </div>
        <div className="col-lg-3 col-md-6">
          <h4 className="text-white mb-4">Tour Packages</h4>
          <Link className="btn btn-link" to="/goldentriangle-india-tour">
          Golden Triangle India Tour
          </Link>
          <Link className="btn btn-link" to="/south-india-tour">
          South India Tour
          </Link>
          <Link className="btn btn-link" to="/rajasthan-tour">
          Rajasthan Tour
          </Link>
          <Link className="btn btn-link" to="/delhi-tour">
          Delhi Tour
          </Link>
          <Link className="btn btn-link" to="/nepal-tour">
          Nepal Tour
          </Link>
          <Link className="btn btn-link" to="/agra-tour">
          Agra Tour
          </Link>
        </div>
        <div className="col-lg-3 col-md-6">
          <h4 className="text-primary mb-4">Newsletter</h4>
          <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p>
          <div className="position-relative w-100">
            <input
              className="form-control bg-transparent w-100 py-3 ps-4 pe-5"
              type="text"
              placeholder="Your email"
            />
            <button
              type="button"
              className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
            >
              SignUp
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Footer End */}
  {/* Copyright Start */}
  <div className="container-fluid copyright py-4">
    <div className="container">
      <div className="row">
        <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
          ©{" "}
          <Link className="fw-medium" to="#">
            Your Site Name
          </Link>
          , All Right Reserved.
        </div>
        <div className="col-md-6 text-center text-md-end">
          Designed By{" "}
          <Link className="fw-medium" to="#">
           
          </Link>{" "}
          Distributed By{" "}
          <Link className="fw-medium" to="#">
            
          </Link>
        </div>
      </div>
    </div>
  </div>
  {/* Copyright End */}
  </div>;
};

export default Footer;
