import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import RajasthanTxt from "../components/RajasthanTxt";

const RajasthanTour = () => {
  return <div>
    <Navbar/>
    <RajasthanTxt/>
    <Footer/>
  </div>;
};

export default RajasthanTour;
