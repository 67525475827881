import React from "react";

const ImgComp = () => {
  return (
    <div>
      {/* Products Start */}
      <div className="container-fluid product py-5 my-5 add3">
        <div className="container py-5">
          <div
            className="col-lg-12  wow fadeInUp"
            data-wow-delay="0.1s"
            // style={{ maxWidth: 500 }}
          >
            <h1 style={{ color: "#000", textAlign: "center" }}>
              Golden Triangle Tour By Privates Drivers India
            </h1>
            <hr />
            <h3 style={{ color: "#000", textAlign: "center" }}>
              Visit India With a Privates Drivers!
            </h3>
          </div>
          <div className="col-lg-12 py-4 row g-4 d-flex align-items-center justify-content-center">
            <div
              className="col-lg-2 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="store-item position-relative text-center">
                <img
                  className="img-fluid"
                  src="/static/img/red.jpg"
                  style={{
                    height: "400px",
                    width: "300px",
                    borderRadius: "50px",
                  }}
                  alt=""
                />
              </div>
            </div>
            <div
              className="col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="store-item position-relative text-center">
                <img
                  className="img-fluid"
                  src="/static/img/taj.jpg"
                  alt=""
                  style={{
                    height: "400px",
                    width: "700px",
                    borderRadius: "50px",
                  }}
                />
              </div>
            </div>
            <div
              className="col-lg-2 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="store-item position-relative text-center">
                <img
                  className="img-fluid"
                  src="/static/img/qutab1.jpg"
                  alt=""
                  style={{
                    height: "400px",
                    width: "200px",
                    borderRadius: "50px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Products End */}
    </div>
  );
};

export default ImgComp;
