import React from "react";
import { Link } from "react-router-dom";

const AboutContent = () => {
  return (
    <div>
      <>
        {/* Page Header Start */}
        <div
          className="container-fluid page-header py-5 mb-5 wow fadeIn"
          data-wow-delay="0.1s"
        >
          <div className="container text-center py-5">
            <h1 className="display-2 text-white mb-4 animated slideInDown">
              Why Us
            </h1>
            <nav aria-label="breadcrumb animated slideInDown">
              <ol className="breadcrumb justify-content-center mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item text-dark" aria-current="page">
                  Why us
                </li>
              </ol>
            </nav>
          </div>
        </div>
        {/* Page Header End */}
        {/* About Start */}
        <div className="container-xxl py-5">
          <div className="container">
            <div className="col-lg-12 wow fadeIn" data-wow-delay="0.5s">
              <div className="section-title" style={{}}>
                <h1>Why Choose Our Car Rental With Driver Services In India</h1>
                <h3 className="py-3">
                  Privates Drivers India will make your holiday in India unique,
                  safe and unforgettable. 5 reasons to choose our travel agency
                  in India
                </h3>
                <p>
                  <b>
                    Privates Drivers India will make your holiday in India
                    unique, safe and unforgettable.
                  </b>
                </p>
                <p
                  className=""
                  style={{
                    fontWeight: "400",
                    marginTop: "10px",
                  }}
                >
                  Our professionalism acquired through our many years of
                  experience allows us to know the needs of our customers. We
                  are aware that you will visit India and that you will want to
                  experience the best of your experience for us in the
                  organization.
                </p>
                <p>
                  <b>Why choose us</b>
                </p>
                <p className="mb-3">
                  Many tour operators operate in India with more or less large
                  capacity. What distinguishes us from other quality-level
                  agencies is the professionalism of our drivers.
                </p>
                <h3>
                  5 GOOD REASONS FOR BOOKING YOUR TOURS IN INDIA WITH PRIVATES
                  DRIVERS INDIA{" "}
                </h3>
                <p className=" mt-3">
                  <b>1) Professional drivers</b>
                </p>
                <p>
                  It is well known that traffic in India is very chaotic and
                  above all if you come from Europe or the United States you
                  will probably not be used to the traffic of the Indian cities.
                  Our drivers allow you to travel to India in absolute safety.
                  Skill, accuracy, knowledge of the territory, professionalism,
                  driving safety are some of the key features of all our
                  drivers. Our drivers know perfectly where they are moving and
                  can provide alternative routes in the event of traffic
                  incidents. All drivers speak fluent English and Hindi. The
                  human aspect is fundamental to us and we would like to
                  emphasize that at the end of your tour your driver will become
                  your best friend in India. F
                </p>
                <p>
                  <b>2) Excellent cars</b>
                </p>
                <p>
                  All the means we use for our tours in India are newly built
                  and in excellent condition. We pay special attention to the
                  maintenance of vehicles to avoid possible mishaps. In the
                  event that it is not possible to finish the tour for a
                  technical annoyance due to the means, we will replace it
                  without charge with a vehicle with the same characteristics or
                  superior features.
                </p>
                <p>
                  <b>3) Easy to book</b>
                </p>
                <p>
                  Our staff is able to assist our clients with kindness and
                  professionalism from the first contact. Let's guess where we
                  can meet the needs of the clients trying to make your
                  experience in India as enjoyable as possible. We can book
                  hotels, flights, buses and trains all over India.
                </p>
                <p>
                  <b>4) Personalized itineraries</b>
                </p>
                <p>
                  On our site we offer some packages already preconfigured as we
                  know the needs of our customers. These packages can be
                  customized according to your requirements or we can make a
                  custom tour for you.
                </p>
                <p>
                  <b>5) Explicit rates</b>
                </p>
                <p>
                  Transparent tariff, no hidden cost and no surprise. During
                  your tours all the costs of the driver (board and lodging) and
                  car expenses (fuel, tolls, local taxes) are already included.
                  Local guides are not included (not necessary but if you are
                  interested we can provide it). Overnight stays at hotels can
                  be booked directly from you or if you wish, we can also book
                  hotels. We have a discount at some of the hotels we know and
                  which we think are well placed to make stops during your
                  tours.
                </p>
                <p><b>Trust our Tour in India an unforgettable experience.</b></p>
              </div>
            </div>
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="row g-3">
                  <div className="col-6 text-end">
                    <img
                      className="img-fluid bg-white w-100 mb-3 wow fadeIn"
                      data-wow-delay="0.1s"
                      src="/static/img/er.jpg"
                      alt=""
                    />
                   
                  </div>
                  <div className="col-6">
                    <img
                      className="img-fluid bg-white w-50 mb-3 wow fadeIn"
                      data-wow-delay="0.3s"
                      src="/static/img/taj.jpg"
                      alt=""
                    />
                    <img
                      className="img-fluid bg-white w-100 wow fadeIn"
                      data-wow-delay="0.4s"
                      src="/static/img/red.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* About End */}
      </>
    </div>
  );
};

export default AboutContent;
