import React from "react";
import img9 from "../images/6_1.png";
import ss1 from "../images/ss1.png";

const NewAbout = () => {
  return (
    <div>
      <div className="container-xxl py-5 add4">
        <div className="container-fluid">
          <div className="row g-5">
            <div className="col-lg-12 wow fadeIn" data-wow-delay="0.5s">
              <div className="section-title" style={{}}>
                <p className="fs-2 fw-medium  text-primary">About Us</p>
                <img src={img9} alt="img" className="img-adj" />
                <p
                  className=""
                  style={{
                    fontWeight: "400",
                    marginTop: "40px",
                  }}
                >
                  We are an association of independent drivers based in New
                  Delhi and we offer different day trips and private tours
                  through Rajasthan and North India. We are available 24 hours a
                  day, 7 days a week and we will send you a quote within 24
                  hours. All our drivers have a professional license and tourist
                  transport insurance for passengers. We also offer prices for
                  small groups of 7 to 10 travellers, contact us to find out
                  more!
                </p>
                <p>
                  Dinesh started his self-employed driving business in Rajasthan
                  and North India over 12 years ago with his father, Ram. Little
                  by little they developed and now there are 10 drivers offering
                  their services. We design circuits and day trips but we are
                  also very flexible and we can create with you your
                  personalized itinerary, off the beaten track. From private day
                  tours to a more personal tour, we are available and attentive
                  to your wishes. You have great freedom of movement, we adapt
                  to your requests and your various needs: medicine, hotel,
                  restaurant, souvenir shop or other establishment recommended
                  by a guide, stops in typical villages or in local markets and
                  bazaars picturesque.
                </p>

                <p>
                  All our drivers are English-speaking and our French partner,
                  Hélène is at your side to answer all your questions and to
                  accompany you in the development of your circuit in Rajasthan
                  and northern India.
                </p>
              </div>
            </div>

            <div className=" wow fadeIn" data-wow-delay="0.5s">
              <div className="section-title" style={{}}>
                <p className="fs-2 fw-medium  text-primary">
                  All Drivers are fully vaccinated.
                </p>

                <p
                  className=""
                  style={{
                    fontWeight: "400",
                    marginTop: "10px",
                  }}
                >
                  The drivers we have not only guide you in your journey to
                  explore India, but they will also tailor make your journey
                  exactly the way you want it to be! Also, all our drivers are
                  experienced and speak good English, this ensures that you not
                  just communicate with them but even form a friendly
                  relationship with them. Along with this, our drivers are
                  experts at communicating with the locals so be sure to
                  experience some lovely interactions along the journey! Icing
                  on the cake is the fact that we also organize small Group
                  Package Tours!
                </p>
                <p>
                  We can make tailor made tours with flexible planning without
                  any hidden agenda or can offer multiple alternatives from
                  which you can make your choice! Also, Our list of tour
                  packages includes single day packages, multiple days packages
                  covering different historic and off beaten paths which will
                  showcase the Indian life. After your tour, you’ll leave India
                  with beautiful memories for a lifetime and immense insights in
                  India’s cultural and historical heritage you can share with
                  family and friends. Note: All Drivers are fully vaccinated
                </p>
              </div>
              <img src={ss1} alt="img" className="img-adj" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewAbout;
