import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import AboutContent from "../components/AboutContent";

const Whyus = () => {
  return <div>
    <Navbar/>
    <AboutContent/>
    <Footer/>
  </div>;
};

export default Whyus;
