import React from "react";
import Navbar from "../components/Navbar";
import HomeBanner from "../components/HomeBanner";
import AboutTxt from "../components/AboutTxt";
import Products from "../components/Products";
import Footer from "../components/Footer";
import Article from "../components/Article";
import Videos from "../components/Videos";
import Store from "../components/Store";
import Message from "../components/Message";
import NewAbout from "../components/NewAbout";
import Portfolio from "../components/Portfolio";
import ImgComp from "../components/ImgComp";
import NewTxt from "../components/NewTxt";

const Home = () => {
  return <div>
    <Navbar/>
    <HomeBanner/>
    <ImgComp/>
    <NewAbout />
    <NewTxt/>
    <AboutTxt/>
    {/* <Products/> */}
    <Article/>
    {/* <Videos/> */}
    <Store/>
    <Message/>
    <Footer/>
  </div>;
};

export default Home;
