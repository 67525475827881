import React from "react";
import img3 from "../images/image_1_1.png";
import img11 from "../images/img3.jpg";
import vid from "../video/vid.mp4";

const HomeBanner = () => {
  return (
    <div>
      {/* Carousel Start */}
      <div className="container-fluid px-0 mb-5">
        <div
          id="header-carousel"
          className="carousel slide carousel-fade"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <video
                src={vid}
                autoPlay
                loop
                muted
                style={{
                  width: "100%",
                  objectFit: "contain",
                }}
              />
              <div className="carousel-caption">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-7 text-center"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Carousel End */}
    </div>
  );
};

export default HomeBanner;
