import React from "react";
import { Link } from "react-router-dom";
import vid from "../video/vid1.mp4";

const DelhiTxt = () => {
  return (
    <div>
      <>
        {/* Page Header Start */}
        <div className="container-fluid px-0 mb-5 add1" style={{}}>
          <div
            id="header-carousel"
            className="carousel slide carousel-fade"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <video
                  src={vid}
                  autoPlay
                  loop
                  muted
                  style={{
                    width: "100%",
                    objectFit: "contain",
                    height: "600px",
                  }}
                />
                <div className="carousel-caption">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-lg-7 text-center"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Page Header End */}
        {/* About Start */}
        <div className="container-xxl py-5 add2">
          <div className="container">
            <div className="col-lg-12 wow fadeIn" data-wow-delay="0.5s">
              <div className="section-title" style={{}}>
                <h1>Why Visit Delhi?</h1>
                <h2 className="py-3">
                  We Explain Why You Should Visit India’s Capital!
                </h2>
                <p
                  className=""
                  style={{
                    fontWeight: "400",
                    marginTop: "10px",
                  }}
                >
                  The capital of India, New Delhi has a lot to offer. A city
                  that offers the perfect blend of art, culture, and modern
                  architecture. Each year countless tourists visit Delhi to be
                  blown away by scenic wonders and jaw-dropping development.
                  Private Driver India gives you few reasons to visit Delhi.
                </p>
                <p>
                  <b> The Perfect Blend:</b> Delhi is home to gorgeous buildings
                  which are known world over like Jama Masjid, Humayun’s Tomb,
                  Qutub Minar, Red Fort & India Gate.
                </p>

                <p>
                  <b>Mouth Watering Cuisines:</b> Your taste buds are sure to be
                  delighted by Delhi’s array of hotels, restaurants, and street
                  food.
                </p>
                <p>
                  <b>A Great Shopping Experience:</b> Delhi doesn’t fail to
                  disappoint avid shoppers, thanks to the plenty options of
                  shopping malls and overall opportunities to shop! Something
                  for everyone is there!
                </p>
                <p>
                  <b> Activities to Explore:</b> Many leisure activities exist
                  in the city so the entertainment aspect of your trip is going
                  to be filled with surprises,
                </p>
              </div>
            </div>

            <div className="row g-5">
              <h1 className="text-center">Delhi Jim Corbett Tour</h1>
              <h2 className="text-center">1 Night 2 Days: Jim Corbett</h2>
              <div className="col-lg-12">
                <div className="row g-4">
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.1s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/4_1.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.3s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/d5.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/d3.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h1 className="py-5">Itinerary:</h1>

            <div className="accordion py-5" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Day 1: Delhi – Jim Corbett National Park
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Our driver will pick you up from your residence or at your
                    point of arrival like airport, railway station etc. You will
                    arrive at the Jim Corbett National Park and upon arrival the
                    formalities to start your journey in the wildlife resort
                    will be completed.
                    <p>You will stay overnight in the resort.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Day 2: Exploring More of Jim Corbett National Park!
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      The Jim Corbett National Park is a paradise for people who
                      love wildlife. After your breakfast you will be taken to a
                      jeep safari through the park. The park is home to more
                      than 586 species of birds and on your way through the park
                      you will spot many Royal Bengal Tigers, Crocodiles,
                      Elephants and more!
                    </p>
                    <p>
                      After the sightseeing you will be taken to Dhikuli which
                      is a rest house within the park. There a panoramic view of
                      the park can also be seen. Dinner will be served to you at
                      the park!
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row g-5 py-5">
              <h1 className="text-center">Delhi Sightseeing Tour</h1>
              <h2 className="text-center">
                Same Day Tour: Old Delhi & New Delhi Attractions
              </h2>
              <div className="col-lg-12">
              <div className="row g-4">
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.1s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/4_1.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.3s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/d5.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/d3.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h1 className="py-5">Itinerary:</h1>

            <div className="accordion py-5" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven"
                    aria-expanded="true"
                    aria-controls="collapseSevrn"
                  >
                    Same Day Tour:
                  </button>
                </h2>
                <div
                  id="collapseSeven"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      In this Full Day Excursion we will explore the best of
                      Delhi with this private driver tour. Delhi is completely
                      full of some of the most amazing sites that India has to
                      offer, in this tour we will cover historical monuments,
                      forts, temples and shopping areas.
                    </p>

                    <p>
                      By embarking on this tour you’d also visit Old Delhi which
                      is a historic city and has a plethora of things for
                      tourists to do. Full Day sightseeing tour will cover
                      multiple historical monuments, Gardens and local markets
                      including:
                    </p>
                    <p>1.The Red Fort</p>
                    <p>2.Jama Masjid</p>
                    <p>3.Humayun’s Tomb</p>
                    <p>4.Rajpath</p>
                    <p>5.India Gate</p>
                    <p>6.Qutub Minar</p>
                    <p>7.Lotus Temple</p>
                    <p>8.Akshardham Temple</p>
                    <p>9.ISKON Temple</p>
                    <p>
                      Experience the magnificence of Delhi and its history with
                      Private Driver India!
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row g-5">
              <h1 className="text-center">Delhi - Agra - Jaipur Tour</h1>
              <h2 className="text-center">2 Nights and 3 Days</h2>
              <div className="col-lg-12">
              <div className="row g-4">
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.1s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/4_1.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.3s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/d5.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    <div className="store-item position-relative text-center">
                      <img
                        className="img-fluid"
                        src="/static/img/d3.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h1 className="py-5">Itinerary:</h1>

            <div className="accordion py-5" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Day 1: Arrival at Delhi – Agra
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    You will get to see the Red Fort, Jama Masjid, Humayun’s
                    Tomb, Qutub Minar, the Parliament House and any other custom
                    destination that you might have added to your package! After
                    which in the evening you will leave for Agra and then stay
                    overnight in Agra.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Day 2: Agra – Jaipur
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    We’d go to see Agra Fort, the Taj Mahal and any other
                    destination that you have selected. After this beautiful
                    sightseeing tour, you will leave for Jaipur and reach there
                    by night for an overnight stay.
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Day 3: Jaipur – Delhi
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Jaipur is vast! So we will cover all the attractions in this
                    day. An elephant ride to Amber Fort will be on the cards and
                    road trip to the city will have you visiting more
                    attractions like Jantar Mantar, Nawab Sahab ki Haveli, the
                    local markets, etc. After this heavy day, you will start
                    your journey back to Delhi airport for your onward Journey!
                  </div>
                </div>
              </div>
            </div>

            <div className="container-xxl contact py-5">
              <div className="container">
                <div className="row g-5">
                  <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                    <h3 className="mb-4">Need a functional contact form?</h3>
                    <p className="mb-4">
                      The contact form is currently inactive. Get a functional
                      and working contact form with Ajax &amp; PHP in a few
                      minutes. Just copy and paste the files, add a little code
                      and you're done. <a href="">Download Now</a>.
                    </p>
                    <form>
                      <div className="row g-3">
                        <div className="col-md-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder="Your Name"
                            />
                            <label htmlFor="name">Your Name</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-floating">
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              placeholder="Your Email"
                            />
                            <label htmlFor="email">Your Email</label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              id="subject"
                              placeholder="Subject"
                            />
                            <label htmlFor="subject">Subject</label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-floating">
                            <textarea
                              className="form-control"
                              placeholder="Leave a message here"
                              id="message"
                              style={{ height: 120 }}
                              defaultValue={""}
                            />
                            <label htmlFor="message">Message</label>
                          </div>
                        </div>
                        <div className="col-12">
                          <button
                            className="btn btn-primary rounded-pill py-3 px-5"
                            type="submit"
                          >
                            Send Message
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="row g-5">
              <div className="col-lg-6">
                <div className="row g-3">
                  <div className="col-6 text-end">
                    <img
                      className="img-fluid bg-white w-100 mb-3 wow fadeIn"
                      data-wow-delay="0.1s"
                      src="/static/img/image_1_1.png"
                      alt=""
                    />
                    <img
                      className="img-fluid bg-white w-50 wow fadeIn"
                      data-wow-delay="0.2s"
                      src="/static/img/image_1_1.png"
                      alt=""
                    />
                  </div>
                  <div className="col-6">
                    <img
                      className="img-fluid bg-white w-50 mb-3 wow fadeIn"
                      data-wow-delay="0.3s"
                      src="/static/img/image_1_1.png"
                      alt=""
                    />
                    <img
                      className="img-fluid bg-white w-100 wow fadeIn"
                      data-wow-delay="0.4s"
                      src="/static/img/image_1_1.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                <div className="section-title"></div>
                <div className="row g-3 mb-4">
                  <div className="col-sm-4">
                    <img
                      className="img-fluid bg-white w-100"
                      src="/static/img/image_1_1.png"
                      alt=""
                    />
                  </div>
                  <div className="col-sm-8">
                    <h5>
                      Our tea is one of the most popular drinks in the world
                    </h5>
                    <p className="mb-0">
                      Tempor erat elitr rebum at clita. Diam dolor diam ipsum
                      sit. Aliqu diam amet diam et eos. Clita erat ipsum et
                      lorem et sit
                    </p>
                  </div>
                </div>
                <div className="border-top mb-4" />
                <div className="row g-3">
                  <div className="col-sm-8">
                    <h5>
                      Daily use of Link cup of tea is good for your health
                    </h5>
                    <p className="mb-0">
                      Tempor erat elitr rebum at clita. Diam dolor diam ipsum
                      sit. Aliqu diam amet diam et eos. Clita erat ipsum et
                      lorem et sit
                    </p>
                  </div>
                  <div className="col-sm-4">
                    <img
                      className="img-fluid bg-white w-100"
                      src="/static/img/image_1_1.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {/* About End */}
      </>
    </div>
  );
};

export default DelhiTxt;
